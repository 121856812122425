import React, { useState } from 'react';
import { FaEnvelope, FaTiktok, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import './AboutUs.css'; // Asegúrate de tener un archivo CSS para los estilos

const AboutUs = ({ config }) => {

  const [formData, setFormData] = useState({
    name: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Crear el enlace mailto
    const mailtoLink = `mailto:${config.correo}?subject=Mensaje de ${formData.name}&body=${encodeURIComponent(formData.message)}`;

    // Redirigir al enlace mailto
    window.location.href = mailtoLink;

  };

  return (
    <div className="about-us-container">
      <h1 className="about-us-title">Conócenos</h1>
      <p className="about-us-description">
        En El Viejo Verde, nuestra aventura comenzó en 2023, en un pequeño taller rodeado de naturaleza, con el sueño de crear una experiencia única para los amantes del cannabis. Nuestra misión es combinar la calidad de nuestros productos con un diseño que celebre la esencia de las plantas y su capacidad para inspirar momentos de conexión y relajación.
      </p>
      <p className="about-us-description">
        Creemos en la dualidad de la vida, donde cada bocanada puede ser un viaje tanto hacia lo sublime como hacia lo introspectivo. Nuestro lema, <strong>"FOR ALL THE WEED DREAMERS"</strong> representa esa fusión de lo bueno y lo desafiante, recordándonos que cada experiencia tiene su valor, y que el equilibrio es fundamental.
      </p>
      <p className="about-us-description">
        Nos esforzamos por ofrecer productos que no solo sigan las tendencias, sino que también reflejen nuestra visión de un consumo responsable. Desde accesorios diseñados con amor hasta productos de cultivo que honran la naturaleza, en El Viejo Verde, cada artículo está pensado para acompañarte en tu camino hacia una vida más plena y consciente.
      </p>

      {/* Redes sociales y correo */}
      <div className="d-flex justify-content-center social-icons-container">
        <a href={`mailto:${config.correo}`} className="social-icon" aria-label="Enviar correo">
          <FaEnvelope />
        </a>
        {config.tiktok ?
          <a href={`https://www.tiktok.com/@${config.tiktok}?_t=8pxVIfRQge8&_r=1`} className="social-icon" aria-label="TikTok">
            <FaTiktok />
          </a>
          : null}
        {config.instagram ?
          <a href={`https://www.instagram.com/${config.instagram}`} className="social-icon" aria-label="Instagram">
            <FaInstagram />
          </a>
          : null}
        {config.whatsapp ?
          <a href={`https://wa.me/34${config.whatsapp}`} className="social-icon" aria-label="WhatsApp">
            <FaWhatsapp />
          </a>
          : null}
      </div>
    </div>
  );
};

export default AboutUs;
