// ModelGallery.jsx
import React from 'react';
import '@google/model-viewer';
import { Margin } from '@mui/icons-material';

const Encargos = ({ models }) => {
    return (
      <div style={styles.galleryContainer}>
        {models.map((modelUrl, index) => (
          <>
            <div key={index} style={styles.card}>
              <model-viewer
                src={modelUrl}
                alt={`3D model ${index + 1}`}
                auto-rotate
                camera-controls
                style={styles.modelViewer}
              ></model-viewer>
            </div>
            <h3>Propuesta {index + 1}</h3>
          </>
        ))}
      </div>
    );
  };
  

const styles = {
  galleryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '30px',
    paddingTop:'40px',
  },
  card: {
    width: '80%',
    height: '300px',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f9f9f9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  modelViewer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#222',
  },
};

export default Encargos;
