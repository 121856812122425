import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductCard from './ProductCard';
import './ProductsCategory.css';
import Categories from './category/Categories';
import './Spinner.css';

const ProductsCategory = ({ products, categories, loading, config }) => {
  const productRefs = useRef([]);
  const navigate = useNavigate();
  const apiUrl = config.apiUrl;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.2,
      }
    );

    productRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [products]);

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  const chunkProducts = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  };

  const productChunks = chunkProducts(products, 4);
  const shouldShowCategories = productChunks.length > 0 && productChunks[0].length > 0;

  return (
    <>
      {loading ? (
        <div className="spinner-overlay">
          <div className="spinner">
            <img src="/path/to/logo.png" alt="Logo" className="spinner-logo" />
          </div>
        </div>
      ) : (
        <div className="featured-products-container">
          {productChunks.map((chunk, chunkIndex) => (
            <React.Fragment key={`chunk-${chunkIndex}`}>
              <div className="featured-products">
                {chunk.map((product, index) => (
                  <div
                    className="product-card-wrapper"
                    key={product.id}
                    ref={el => productRefs.current[chunkIndex * 4 + index] = el}
                  >
                    <ProductCard
                      title={product.name}
                      price={product.price}
                      image={`${apiUrl}${product.images[0]}`}
                      onClick={() => handleProductClick(product.id)}
                    />
                  </div>
                ))}
              </div>
              {chunkIndex < productChunks.length - 1 && shouldShowCategories && (
                <Categories config={config} />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default ProductsCategory;
