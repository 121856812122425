export const modelUrls = [
  'https://elviejoverde.me/img/ceim_1.glb',
  'https://elviejoverde.me/img/ceim_2.glb',
  'https://elviejoverde.me/img/ceim_3.glb'
];

export const modelUrls2 = [
  'https://elviejoverde.me/img/boda_1.glb',
  'https://elviejoverde.me/img/boda_2.glb',
  'https://elviejoverde.me/img/boda_3.glb'
];