import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import PromoBanner from './components/PromoBanner';
import ProductsCategory from './components/ProductsCategory';
import ProductDetails from './components/ProductDetails';
import ShippingPage from './components/ShippingPage';
import OrderProcessing from './components/OrderProcessing';
import OrderDetails from './components/OrderDetails';
import OrdenProducts from './components/OrdenProducts';
import ScrollToTop from './components/ScrollToTop'; // Asegúrate de que el nombre sea correcto
import CartPage from './components/CartPage';
import ProtectedRoute from './components/admin/ProtectedRoute';
import CategoryPage from './components/CategoryPage';
import AboutUs from './components/AboutUs';
import Best from './components/Best';
import Locker from './components/Locker';
import ProductCollection from './components/ProductCollection';
//import VideoFin from './components/VideoFin';
import CategoryList from './components/CategoryList';

import Encargos from './components/Encargos';
import { modelUrls, modelUrls2 } from './context/EncargosContext';








// Config inicial
const initialConfig = {
  apiUrl: 'https://elviejoverde.me',
  appName: 'elviejoverde',
  correo: 'elviejoverde.me@gmail.com',
  password: 'chulo',

  envioGratis: '20',
  textBanner1: '"Porque hasta el Viejo necesita un toque 3D en su vida."',
  textBanner2: 'No te preocupes, aquí solo se fuma lo bueno.',

  tiktok: '',
  instagram: 'elviejoverde.me',
  whatsapp: '',

  lineaUno: 'FOR ALL THE DREAMERS',
  lineaDos: 'EVEN THE OLD MAN NEEDS A BREAK',
  lineaTres: 'GRAB IT BEFORE IT’S GONE.',
  botonComprar: 'Shop now 🫶🏽',

  textFooter: '© ElViejoVerde',

  video: false,
  theme: {
    background: '#A5D6A7', // Color de fondo del Navbar (verde claro)
    // Navbar: categorias, hamburguesa, icoBuscar, carrito
    text: '#F5F5F5', // Texto blanco para buen contraste
    textHover: '#FFC300', // Resaltado (hover) amarillo claro

    // Icono buscar en el desplegable
    buttonBg: '#388E3C', // Verde brillante para icono buscar
    buttonHover: '#FFEB3B', // Amarillo mostaza para hover en icono buscar

    // Letrero arriba del todo
    colorBackgroundBanner: '#5D4037', // Color de fondo marrón terroso
    colorTextBanner: '#A5D6A7', // Texto verde claro en el banner

    // Botón shopNow del medio
    colorBackgroundShopnow: 'rgba(165, 214, 167, 0.85)', // Fondo verde claro del botón
    colorTextBorderShopnow: '#5d4037', // Texto y borde amarillo claro
  }
};

const App = () => {
  const [config, setConfig] = useState(initialConfig); // Inicializa el estado de la configuración
  const [categories, setCategories] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = config.appName; // Establecer el título dinámicamente
    applyTheme(config);
  }, [config]); // Asegúrate de actualizar el título cuando cambie el nombre de la app

  useEffect(() => {
    const fetchConfigFromApi = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/config`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const apiResponse = await response.json();
        setConfig((prevConfig) => ({
          ...prevConfig,
          ...apiResponse,
        })); // Actualiza el estado con la nueva configuración
      } catch (error) {
        console.error('Error al obtener los datos de la API:', error);
      }
    };

    fetchConfigFromApi(); // Llama a la función para obtener la configuración
  }, []); // Solo se ejecuta una vez al montar el componente

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/category`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const categoriesMap = {};

        data.forEach(category => {
          if (category.urlImg && category.urlImg.trim() !== "" && category.subCategory) {
            categoriesMap[category.subCategory] = category.urlImg;
          } else if (category.urlImg && category.urlImg.trim() !== "" && category.category) {
            categoriesMap[category.category] = category.urlImg;
          }
        });

        setCategories(categoriesMap);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/products`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const filteredProducts = data.filter(product => !product.locker && !product.bestFriends);
        setProducts(filteredProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    fetchProducts();
  }, [config.apiUrl]);

  const applyTheme = (config) => {
    const root = document.documentElement;
    root.style.setProperty('--color-background', config.theme.background);
    root.style.setProperty('--color-text', config.theme.text);
    root.style.setProperty('--color-text-hover', config.theme.textHover);
    root.style.setProperty('--color-navbar-bg', config.theme.background);
    root.style.setProperty('--color-offcanvas-bg', config.theme.background);
    root.style.setProperty('--color-button-bg', config.theme.buttonBg);
    root.style.setProperty('--color-button-hover', config.theme.buttonHover);
    root.style.setProperty('--color-icon', config.theme.text);
    root.style.setProperty('--color-icon-hover', config.theme.textHover);
    root.style.setProperty('--color-banner', config.theme.colorBackgroundBanner);
    root.style.setProperty('--color-text-banner', config.theme.colorTextBanner);
    root.style.setProperty('--color-background-shopnow', config.theme.colorBackgroundShopnow);
    root.style.setProperty('--color-text-border-shopnow', config.theme.colorTextBorderShopnow);
    root.style.setProperty('--footer-bg-color', config.theme.colorFooterBg);
    root.style.setProperty('--footer-text-color', config.theme.colorFooterText);
  };

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <PromoBanner config={config} />
            <ProductsCategory
              products={products}
              categories={categories}
              loading={loading}
              config={config} // Puedes pasar otras configuraciones si es necesario
            />
            <CategoryList config={config} />
            {/*<VideoFin />*/}
          </>
        } />
        <Route path="/product/:id" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <ProductDetails config={config} />
          </>
        } />
        <Route path="/envio/:email" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <ShippingPage config={config} />
          </>
        } />
        <Route path="/cart" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <CartPage config={config} />

          </>
        } />
        <Route path="/orderProcessing/:email/:mobileNumber/:fullAddress/:typeShipping" element={
          <>
            <OrderProcessing config={config} />
          </>
        } />
        <Route path="/orderDetails/:id" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <OrderDetails config={config} />
          </>
        } />
        <Route path="/manage" element={
          <ProtectedRoute config={config} />
        } />
        <Route path="/about" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <AboutUs config={config} />
          </>
        } />
        <Route path="/best" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <Best config={config} />
          </>
        } />
        <Route path="/locker" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <Locker config={config} />
          </>
        } />
        <Route path="/collections" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <ProductCollection config={config} />
          </>
        } />
        <Route path="/category/:category" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <CategoryPage config={config} />
          </>
        } />
        <Route path="/encargos" element={
          <>
            <Encargos models={modelUrls} />
          </>
        } />
        <Route path="/encargosBoda" element={
          <>
            <Encargos models={modelUrls2} />
          </>
        } />
      </Routes>
    </div>
  );
};

export default App;
